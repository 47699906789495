<template>
  <list-template :current-page="page" :loading="loading" :table-config="tableConfig" :table-data="tableData" :total="total" @onAdd="handleAdd" @onChangePage="handleCurrentChange" @onHandle="handleClick">
    <!--    <template slot="title">-->
    <!--      <div class="mb-2"><el-button size="small" type="primary" @click="handleAdd">新增</el-button></div>-->
    <!--    </template>-->
  </list-template>
</template><script>
import {postApi2} from '@/api'
import {tableListMixin} from '@/utils/mixins'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  name: 'List',
  mixins: [tableListMixin],
  created() {},
  methods: {
    getData() {
      this.getDataApi('/api2/api/tool/white-list/index')
    },
    handleAdd() {
      this.$router.push('./add')
    },
    handleClick(row) {
      let that = this
      this.$confirm('删除后，将不会访问到此页面', '提示', {
        confirmButtonText: '仍要删除',
        cancelButtonText: '点错了',
        type: 'warning'
      }).then(() => {
        postApi2('/tool/white-list/del', { id: row.id }).then(() => {
          that.getData()
        })
      })
    }
  },
  data() {
    return {
      tableConfig: [
        {
          type: 'number',
          label: '序号',
          width: '90rem'
        },
        {
          prop: 'content',
          label: '域名'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          width: '160rem',
          handle: true,
          render() {
            return ['删除']
          }
        }
      ]
    }
  }
}
</script>
